// import pdf1 from 'assests/Pdf/saras2.pdf';

const pages = {
  landings: [
    // {
    //   title: 'FAQs',
    //   href: '/faqs',
    // },
    // {
    //   title: 'Desktop App',
    //   href: '/desktop-app',
    // },
    // {
    //   title: 'Startup',
    //   href: '/startup',
    // },
    // {
    //   title: 'Expo',
    //   href: '/expo',
    // },
    
    
    // {
    //   title: 'Service',
    //   href: '/service',
    // },
    // {
    //   title: 'Enterprise',
    //   href: '/enterprise',
    // },
    // {
    //   title: 'Cloud Hosting',
    //   href: '/cloud-hosting',
    // },
    // {
    //   title: 'Design Company',
    //   href: '/design-company',
    // },
    // {
    //   title: 'Web Basic',
    //   href: '/web-basic',
    // photo-gallery
    // },
    // {
    //   title: 'Overview',
    //   href: '/admission-criteria',
    // },
    // {
    //   title: 'Agency',
    //   href: '/Agency',
    // },
    // {
    //   title: 'E-Learning',
    //   href: '/e-learning',
    // },
    // {
    //   title: 'Coworking',
    //   href: '/coworking',
    // },
    // {
    //   title: 'Rental',
    //   href: '/rental',
    // },
    // {
    //   title: 'Job Listing',
    //   href: '/job-listing',
    // },
    // {
    //   title: 'Logistics',
    //   href: '/logistics',
    // },
    // {
    //   title: 'E-commerce',
    //   href: '/e-commerce',
    // },
  ],
  company: [
    {
      title: 'History',
      href: '/history',
    },
    
    {
      title: 'Vision & Mission',
      href: '/vision-and-mission',
    },
    {
      title: 'Overview',
      href: '/AboutUs',
    },
    {
      title: 'Leadership',
      href: '/leader-ship',
    },
    {
      title: 'Resources',
      href: '/resources',

    },
    {
      title: 'I-5-Academy',
      href: '/I-5-Academy',
    },
    {
      title: 'Achievements',
      href: '/achievements ',
    },
    {
      title: ' Testimonial',
      href: '/testimonial',
    },     
    {
      title: 'Alumni',
      href: '/alumni-2024',
    },
    {
      title: 'Mandatory Public Disclosure',
      href: 'https://npsypr-website.blr1.cdn.digitaloceanspaces.com/static-assets/current/home/home-static/static-images/all-static-images/pdf/saras2.pdf',
    },
    {
      title: 'Credentia',
      href: '/credentia',
    },
   
   
    // {
    //   title: 'About: Startup',
    //   href: '/about-side-cover',
    // },
    // {
    //   title: 'About',
    //   href: '/about',
    // },
    // {
    //   title: 'Pricing',
    //   href: '/pricing',
    // },
    // {
    //   title: 'Terms',
    //   href: '/company-terms',
    // },
    // {
    //   title: 'Contact: Map',
    //   href: '/contact-sidebar-map',
    // },
    // {
    //   title: 'Contact: Minimal',
    //   href: '/contact-page-cover',
    // },
    // {
    //   title: 'Contact: Startup',
    //   href: '/contact-page',
    // },
  ],
  account: [
    // {
    //   title: 'Admissions: Overview',
    //   href: '/admission-overview',
    // },
    {
      title: 'Criteria',
      href: '/admission-criteria',
    },
    {
      title: 'Entrance Test',
      href: '/entrance-test-details',
    },
    {
      title: 'FAQs',
      href: '/faqs',
    },
    // {
    //   title: 'Sign up: Simple',
    //   href: '/admission-overview',
    // },
    // {
    //   title: 'Sign up: Cover',
    //   href: '/signup-cover',
    // },
    // {
    //   title: 'Sign in: Simple',
    //   href: '/signin-simple',
    // },
    // {
    //   title: 'Sign in: Cover',
    //   href: '/signin-cover',
    // },
    // {
    //   title: 'Password: Simple',
    //   href: '/password-reset-simple',
    // },
    // {
    //   title: 'Password: Cover',
    //   href: '/password-reset-cover',
    // },
  ],
  secondary: [
    // {
    //   title: 'Learning: Overview',
    //   href: '/learning-overview',
    // },

    {
      title: 'Scholastic',
      href: '/scholastic',
    },
    {
      title: 'Co-Scholastic',
      href: '/co-scholastic',
    },
    
    {
      title: 'Enrichment Programmes',
      href: '/enrichment-programmes',
    },
    {
      title: 'NEP 2020 In School Education',
      href: '/nep-2020',
    },

    // {
    //   title: 'Error 404: Simple',
    //   href: '/not-found',
    // },
    // {
    //   title: 'Error 404: Cover',
    //   href: '/not-found-cover',
    // },
    // {
    //   title: 'Career Page Minimal',
    //   href: '/career-listing-minimal',
    // },

  ],

  blog: [
    {
      title: 'Facilities',
      href: '/facilities',
    },
    {
      title: 'Co-Curricular Activities',
      href: '/cca',
    },
    {
      title: 'Events',
      href: '/eventss',
    },
  
    {
      title: 'Photo Gallery',
      href: '/photo-gallery2024',
    },
    {
      title: 'Circular',
      href: '/circular',
    },
    {
      title: 'Blogs',
      href: '/blogs',
    },
    {
      title: 'Careers',
      href: '/careers',
    },
  ],
  portfolio: [
    {
      title: 'Our Contact',
      href: '/contact-us',
    },

    // {
    //   title: 'Enquiry',
    //   href: '/enquiry',
    // },

    // {
    //   title: 'Query Us',
    //   href: '/query-us',
    // },
    // {
    //   title: 'Parallax',
    //   href: '/Agency',
    // },
    
  ],
};

export default pages;